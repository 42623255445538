<template>
  <div class="header">
    <img src="./assets/splice_logo.png" width="200" />
    <span class="header-text"> AUDIO LIBRARY </span>
  </div>
  <div v-if="!authenticated"><log-in @login="login" /></div>
  <div v-else>
    <div class="controls">
      <a-input
        placeholder="Search files"
        enter-button="Search"
        @search="onSearch"
        @change="onSearchTextChange"
        :value="searchString"
      />
      <a-button v-if="searchString" @click="clearSearchInput">Clear</a-button>
      <a-button @click="$refs.file.click()" class="add-button">Add</a-button>
      <input type="file" ref="file" @change="uploadFile" class="hide" />
    </div>
    <div class="grid-wapper">
      <div class="grid-left">
        <div>
          <a-table
            :columns="columns"
            :data-source="data"
            :customRow="customRow"
          />
        </div>
      </div>
      <div class="grid-right">
        <edit-file-meta
          v-if="activeRecord"
          :active-record="activeRecord"
          :voice-talents="voiceTalents"
          :tag-list="tagList"
          @onUpdate="onUpdate"
          @onPublish="onPublish"
          @onUnPublish="onUnPublish"
        />
      </div>

      <upload-modal
        v-model:show-modal="showModal"
        :audio-file="audio_file"
        :voice-talents="voiceTalents"
        @closeModal="closeModal"
        @uploadSuccess="uploadSuccess"
      />
    </div>
  </div>
</template>

<script>
import { Button } from "ant-design-vue";
import { Table } from "ant-design-vue";
import { InputSearch } from "ant-design-vue";
import UploadModal from "./components/UploadModal.vue";
import EditFileMeta from "./components/EditFileMeta.vue";
import LogIn from "./components/Login.vue";
import axios from "axios";
import { columns } from "./components/config.js";

export default {
  name: "App",
  components: {
    AButton: Button,
    ATable: Table,
    AInput: InputSearch,
    UploadModal,
    EditFileMeta,
    LogIn,
  },
  data: () => ({
    data: [],
    activeRecord: null,
    showModal: false,
    audio_file: null,
    fileModalData: {},
    columns,
    authenticated: false,
    searchString: "",
    tagList: [],
    voiceTalents: [
      "Liz",
      "Mandy",
      "Tom",
      "Jason",
      "Jean",
      "Derek",
      "Alex",
      "Allison",
      "Amy",
      "Cecilia",
      "Esteban",
      "Mike",
      "Nathalie",
      "Paul",
      "Roxanne",
    ],
  }),
  mounted: function () {
    axios.defaults.baseURL = window.location.hostname.includes(
      "splicesoftware.com",
    )
      ? "https://audiomgm-api.splicesoftware.com"
      : "http://localhost:8000";
    axios.get("/audio/").then((ret) => (this.data = ret.data));
    axios.get("/audio/tag_list/").then((ret) => (this.tagList = ret.data));
  },
  methods: {
    customRow: function (record) {
      return {
        onClick: () => {
          this.activeRecord = { ...record };
        },
      };
    },
    closeModal: function () {
      this.showModal = false;
    },
    onPublish: function (record) {
      axios.post(`/audio/publish_file/${record.id}/`, {}).then((ret) => {
        ret = ret.data;
        let index = this.data.findIndex((record) => record.id === ret.id);
        this.data.splice(index, 1, ret);
        this.activeRecord = ret;
      });
    },
    onUnPublish: function (record) {
      axios.post(`/audio/unpublish_file/${record.id}/`, {}).then((ret) => {
        ret = ret.data;
        let index = this.data.findIndex((record) => record.id === ret.id);
        this.data.splice(index, 1, ret);
        this.activeRecord = ret;
      });
    },
    onUpdate: function (record) {
      console.log(JSON.stringify(record));
      record.tags = record.tags.map((t) => ({ ...t, file: record.id }));
      axios.patch(`/audio/${record.id}/`, record).then((ret) => {
        ret = ret.data;
        let index = this.data.findIndex((record) => record.id === ret.id);
        this.data.splice(index, 1, ret);
        this.activeRecord = ret;
      });
    },
    uploadFile() {
      this.audio_file = this.$refs.file.files[0];
      this.showModal = true;
    },
    startAdd: function () {
      let input = this.document.createElement("input");
      input.type = "file";
      input.click();
    },
    login: function (password) {
      axios.post(`/audio/login/`, { password }).then(() => {
        this.authenticated = true;
      });
    },
    uploadSuccess: function (data) {
      this.data.unshift(data);
      this.showModal = false;
    },

    clearSearchInput: function () {
      this.searchString = "";
      axios.get("/audio/").then((ret) => (this.data = ret.data));
    },
    onSearchTextChange: function (e) {
      this.searchString = e.target.value;
    },
    onSearch: function (search) {
      axios
        .get("/audio/search/", { params: { search } })
        .then((ret) => (this.data = ret.data));
    },
  },
};
</script>
<style scoped>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
.header {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 65;
  background-color: #131f6b;
  padding: 10;
  padding-left: 30;
  align-items: center;
  padding: 10px;
  padding-left: 20px;
}
</style>
<style>
.header-text {
  color: white;
  margin-left: 10px;
  font-family: sans-serif, Montserrat;
  margin-top: 25px;
}
.table-wrapper {
  width: 100%;
  display: inline-flex;
  justify-content: center;
  background-color: blue;
}
.controls {
  display: flex;
  max-width: 60%;
  margin: auto;
  margin-top: 3em;
  margin-bottom: 3em;
}
.grid-wapper {
  display: flex;
  flex-direction: row;
}
.grid-left {
  width: 56%;
  margin-left: 4%;
  margin-right: 0%;
}
.grid-right {
  width: 36%;
  margin-left: 2%;
  margin-right: 2%;
  padding-bottom: 20px;
}

.meta-input {
  width: 50%;
}
.modal-data-field {
  margin-bottom: 10px;
}
.modal-upload-filename {
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 15px;
}
.hide {
  display: none;
}
.add-button {
  margin-left: 20px;
}
</style>
