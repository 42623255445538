export const columns = [
  {
    title: "ID",
    dataIndex: "id",
    key: "id",
    customRender: (obj) => <div style="font-size: 10px">{obj.record.id}</div>,
  },
  {
    title: "File Name",
    dataIndex: "filename",
    key: "filename",
  },
  {
    title: "Language",
    dataIndex: "language",
    key: "language",
  },
  {
    title: "Voice Talent",
    dataIndex: "voice_talent",
    key: "voice_talent",
  },
  {
    title: "Path",
    dataIndex: "path",
    key: "path",
    customRender: (obj) => <div style="font-size: 10px">{obj.record.path}</div>,
  },
  {
    title: "Published",
    dataIndex: "published",
    key: "published",
    customRender: (obj) => (obj.record.published ? "yes" : "no"),
  },
];
