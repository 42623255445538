<template>
  <div class="meta-box">
    <a-input
      class="input-box"
      v-model:value="password"
      placeholder="password"
      v-on:keyup.enter="handleEnter"
    />
  </div>
</template>
<script>
import { Input } from "ant-design-vue";

export default {
  name: "LogIn",
  components: {
    AInput: Input.Password,
  },
  props: {
    activeRecord: { type: Object, default: () => ({}) },
  },

  data: () => ({
    password: null,
  }),
  methods: {
    handleEnter: function () {
      this.$emit("login", this.password);
    },
  },
};
</script>
<style scoped>
.meta-box {
  width: 100%;
  height: 80vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: center;
}
.input-box {
  width: 40%;
  max-width: 500px;
}
</style>
