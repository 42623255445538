<template>
  <a-modal
    :visible="showModal"
    title="File Upload Details"
    okText="Upload"
    @cancel="$emit('closeModal')"
  >
    <template #footer>
      <a-button key="back" @click="handleCancel">Cancel</a-button>
      <a-button
        key="submit"
        type="primary"
        :loading="loading"
        @click="handleUploadPress"
        >Submit</a-button
      >
    </template>
    <div class="modal-upload-filename">
      {{ audioFile.name }}
    </div>
    <div>Account ID</div>
    <div class="modal-data-field">
      <a-input
        class="meta-input"
        v-model:value="fileModalData.account_id"
        placeholder=""
      />
    </div>
    <div>Library Path</div>
    <div class="modal-data-field">
      <a-input
        class="meta-input"
        v-model:value="fileModalData.library_path"
        placeholder="folder/subfolder/subfolder"
      />
    </div>

    <div class="modal-data-field">
      <div>Voice Talent</div>
      <a-select
        ref="select"
        v-model:value="fileModalData.voice_talent"
        style="width: 120px"
        @focus="focus"
      >
        <a-select-option
          v-for="talent in voiceTalents"
          :key="talent"
          :value="talent.toLowerCase()"
          >{{ talent }}</a-select-option
        >
      </a-select>
    </div>
    <div class="modal-data-field">
      <div>Language</div>
      <a-select
        ref="select"
        v-model:value="fileModalData.language"
        style="width: 120px"
      >
        <a-select-option value="en">English</a-select-option>
        <a-select-option value="fr">French</a-select-option>
        <a-select-option value="sp">Spanish</a-select-option>
      </a-select>
    </div>
    <div class="meta-input">
      <div>Transcript</div>
      <a-textarea
        addon-before="transcript"
        :value="fileModalData.transcript"
        placeholder=""
      />
    </div>
    <div v-if="errorMessage" class="error-message">{{ errorMessage }}</div>
  </a-modal>
</template>
<script>
import axios from "axios";
import {
  Button,
  Input,
  Modal,
  Textarea,
  Select,
  SelectOption,
} from "ant-design-vue";

export default {
  name: "UploadModal",
  components: {
    AButton: Button,
    AInput: Input,
    AModal: Modal,
    ATextarea: Textarea,
    ASelect: Select,
    ASelectOption: SelectOption,
  },
  props: {
    audioFile: { type: Object, default: () => ({}) },
    showModal: { type: Boolean, default: true },
    voiceTalents: { type: Array, default: () => [] },
  },
  data: () => ({
    fileModalData: {},
    errorMessage: null,
    loading: false,
  }),
  methods: {
    handleUploadPress: function () {
      if (this.fileModalData.account_id) {
        this.loading = true;
        this.submitFile(this.fileModalData);
      } else {
        this.errorMessage = "Account ID is required";
      }
    },
    handleCancel: function () {
      this.loading = false;
      this.$emit("closeModal");
    },
    submitFile(fileModalData) {
      const formData = new FormData();
      formData.append("file", this.audioFile);
      const headers = { "Content-Type": "multipart/form-data" };
      formData.append(
        "data",
        JSON.stringify({
          path: fileModalData.library_path,
          account_id: fileModalData.account_id,
          voice_talent: fileModalData.voice_talent,
        })
      );

      axios
        .post("/audio/fileupload/", formData, {
          headers,
        })
        .then((res) => {
          this.loading = false;
          this.$emit("uploadSuccess", res.data);
          // if (res.data) this.data.unshift(res.data);
        })
        .catch((err) => {
          this.loading = false;
          this.errorMessage = err;
        });
    },
  },
};
</script>
<style scoped>
.error-message {
  color: red;
  margin-top: 26px;
}
</style>
