<template>
  <div class="meta-box">
    <div class="modal-upload-filename">{{ localData.filename }}</div>
    <div class="splits">
      <div class="left-split">
        <div class="modal-data-field">
          <div>Path</div>
          <a-input
            class="meta-input"
            v-model:value="localData.path"
            placeholder="folder/subfolder/subfolder"
            :disabled="localData.published"
          />
        </div>
        <div class="modal-data-field">
          <div>Voice Talent</div>
          <a-select
            ref="select"
            v-model:value="localData.voice_talent"
            style="width: 120px"
            @focus="focus"
          >
            <a-select-option
              v-for="talent in voiceTalents"
              :key="talent"
              :value="talent.toLowerCase()"
              >{{ talent }}</a-select-option
            >
          </a-select>
        </div>
        <div class="modal-data-field">
          <div>Language</div>
          <a-select
            ref="select"
            v-model:value="localData.language"
            style="width: 120px"
            @focus="focus"
          >
            <a-select-option value="en">English</a-select-option>
            <a-select-option value="fr">French</a-select-option>
            <a-select-option value="sp">Spanish</a-select-option>
          </a-select>
        </div>

        <div class="flex-published">
          <div>Published</div>
          <check-circle-outlined v-if="localData.published" class="green" />
          <close-circle-outlined v-else class="red" />
        </div>
      </div>
      <div class="right-split">
        <div><strong>Tags</strong></div>
        <div v-for="tag in localData.tags" :key="tag.id" class="tag-row">
          <div class="tag-select">{{ tag.tag }}</div>
          <a-input class="meta-input" v-model:value="tag.value" />
          <minus-circle-outlined
            @click="handleRemoveTag(tag)"
            class="tag-button"
          />
        </div>
        <div class="tag-row">
          <a-select
            ref="select"
            v-model:value="newTag.tag"
            style="width: 200px"
            @focus="focus"
            class="tag-select"
          >
            <a-select-option v-for="tag in tagList" :key="tag" :value="tag">{{
              tag
            }}</a-select-option>
          </a-select>
          <a-input class="meta-input" v-model:value="newTag.value" />
          <plus-circle-outlined @click="handleAddNewTag" class="tag-button" />
        </div>
      </div>
    </div>
    <div>Transcript</div>
    <a-textarea
      v-model:value="localData.transcript"
      :autosize="{ minRows: 3 }"
    />
    <div class="flex-buttons">
      <a-button @click="$emit('onUpdate', localData)">Update</a-button>
      <a-button
        v-if="!localData.published"
        @click="$emit('onPublish', localData)"
        >Publish</a-button
      >
      <a-button v-else @click="$emit('onUnPublish', localData)"
        >Un-Publish</a-button
      >
    </div>
  </div>
</template>
<script>
import { Button, Input, Textarea, Select, SelectOption } from "ant-design-vue";
import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  PlusCircleOutlined,
  MinusCircleOutlined,
} from "@ant-design/icons-vue";

export default {
  name: "EditFileMeta",
  components: {
    AButton: Button,
    AInput: Input,
    ATextarea: Textarea,
    ASelect: Select,
    ASelectOption: SelectOption,
    CheckCircleOutlined,
    CloseCircleOutlined,
    PlusCircleOutlined,
    MinusCircleOutlined,
  },
  props: {
    activeRecord: { type: Object, default: () => ({}) },
    voiceTalents: { type: Array, default: () => [] },
    tagList: { type: Array, default: () => [] },
  },

  data: () => ({
    localData: {},
    newTag: {},
  }),
  mounted: function () {
    this.localData = { ...this.activeRecord };
  },
  watch: {
    activeRecord: function () {
      this.localData = { ...this.activeRecord };
    },
  },
  methods: {
    handleAddNewTag: function () {
      this.localData.tags.push(this.newTag);
      this.newTag = {};
    },
    handleRemoveTag: function (tag) {
      this.localData.tags = this.localData.tags.filter(
        (t) => t.tag !== tag.tag
      );
    },
  },
};
</script>
<style scoped>
.flex-buttons {
  width: 50%;
  margin-top: 30px;
  align-self: center;
  display: flex;
  justify-content: space-around;
}
.flex-published {
  align-items: center;
  justify-content: center;
  display: flex;
  margin-bottom: 10px;
}
.green {
  color: green;
  margin-left: 10px;
}
.red {
  color: red;
  margin-left: 10px;
}
.meta-box {
  border-radius: 5px;
  padding-top: 10px;
  padding-left: 30px;
  width: 100%;
  height: 300px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.splits {
  display: flex;
  flex-direction: row;
  width: 100%;
}
.left-split {
  width: 40%;
}
.right-split {
  width: 60%;
}
.tag-row {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}
.meta-input {
  width: 80%;
}
.tag-select {
  margin-right: 5px;
}
.tag-button {
  margin-left: 5px;
}
</style>
